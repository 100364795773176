<template>
  <div class="cell-mcn">
    <img
      class="avatar"
      src="@/assets/images/icon-mcn@2x.png"
    >
    <div class="middle">
      <span class="title">{{ data && data.name ? data.name : '-' }}</span>
    </div>
    <div class="arrow" />
  </div>
</template>

<script>
export default {
  name: 'CellMcn',
  props: {
    data: {
      type: Object,
      default() {
        return null;
      },
    }
  },
};
</script>

<style lang="less" scoped>
.cell-mcn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  margin: 0 40px;
  border-bottom: 1px solid #E0E0E0;
  cursor: pointer;

  .avatar {
    flex-shrink: 0;
    width: 108px;
    height: 108px;
    border-radius: 60px;
    margin-left: 20px;
  }

  .middle {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .title {
      font-size: 32px;
      font-weight: 600;
      color: #333;
      line-height: 32px;
      margin-left: 30px;
    }
  }


  .arrow {
    margin-right: 20px;
    width: 16px;
    height: 28px;
    background-image: url("~@/assets/images/icon-more-grey@2x.png");
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;
  }

  &:active .arrow {
    background-image: url("~@/assets/images/icon-more-blue@2x.png");
  }
}
</style>